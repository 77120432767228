import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getCategory = createAsyncThunk(
  "category/getCategory",
  async () => {
    const response = await fetch(
      "https://hsmtxtzh1f.execute-api.eu-central-1.amazonaws.com/Prod/get_image",
      {
        method: "POST",
        body: JSON.stringify({ path: "mf1851/category.txt" }),
        headers: {
          Accept: "application/json",
        },
      }
    );
    const categoryResponse = await response.json();
    return categoryResponse;
  }
);

export const categorySlice = createSlice({
  name: "categories",
  initialState: {
    category: [],
    isLoading: false,
  },
  extraReducers: {
    [getCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getCategory.fulfilled]: (state, action) => {
      state.category = action.payload;
      state.isLoading = false;
    },
    [getCategory.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default categorySlice.reducer;
