import React, { useEffect } from "react";
import {  useSelector } from "react-redux";

import Category from "./Category";
import "../style/home.css";

const Home = () => {


  const categorys = useSelector((state) => state.categories.category);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 

  return (
    <div className="App">
      <div className="header">
        <h4 className="text-center mt-2">MENU</h4>
      </div>
      <div className="m-2">
      <Category categorys={categorys} />
      </div>
    </div>
  );
};

export default Home;
