import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

import galleryReducer from "./state/galleryState";
import categoriesReducer from "./state/categoryState";
import imagesReducer from "./state/imageState";
import "bootstrap/dist/css/bootstrap.min.css";

const store = configureStore({
  reducer: {
    gallery: galleryReducer,
    categories: categoriesReducer,
    images: imagesReducer,
  
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store={store}>
    <App />
  </Provider>
);


