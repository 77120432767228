import React,{useState,useEffect} from "react";
import Lottie from "react-lottie";
import loading from "../assets/lottie/loading.json";
// import mobres from "../assets/mobres-logo.png";
import "../style/loading.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProduct } from "../state/galleryState";
import { getCategory } from "../state/categoryState";

const Loading = () => {
  const [counter, setCounter] = useState(5);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProduct());
    dispatch(getCategory());
  }, [dispatch]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  if(counter===0){
    navigate("/FirstPage");
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="loading-container">
    <div className="loading">
      {/* <img src={mobres} alt="" className="logo" /> */}
      <div className="lottie-container">
      <Lottie options={defaultOptions} className="lottie" />
      </div>
    </div>
    </div>
  );
};

export default Loading;
