import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import resim from "../assets/product/resim2.jpeg";
import frozen from "../assets/product/resim7.png";
import sicak from "../assets/product/sicak.png";
import espresso from "../assets/product/espresso.png";
import bitki from "../assets/product/bitki.jpeg";
import cay from "../assets/product/cay.jpeg";
import filtre from "../assets/product/filtre.jpeg";
import soft from "../assets/product/soft.png";
import milksake from "../assets/product/milksake.png";
import mojito from "../assets/product/mojito.png";
import nargile from "../assets/product/nargile.png";
import limonata from "../assets/product/limonata.png";
import sogukkahve from "../assets/product/sogukkahve.jpeg";
import tazesıkılmıs from "../assets/product/tazesıkılmıs.png";
import türkkahvesi from "../assets/product/türkkahvesi.jpeg";
import pasta from "../assets/product/pasta.png";

const ProductCard = ({ item }) => {
  const [modal, setmodal] = useState(false);
  const [image, setİmage] = useState({ resim });
  // console.log(item.CATEGORY);

  useEffect(() => {
    if (item.CATEGORY === "FROZEN") {
      setİmage(frozen);
    } else if (item.CATEGORY === "SICAK ICECEK") {
      setİmage(sicak);
    }
    else if (item.CATEGORY === "ESPRESSO CESITLERI") {
      setİmage(espresso);
    }
    else if (item.CATEGORY === "BITKI CAY") {
      setİmage(bitki);
    }
    else if (item.CATEGORY === "CAYLAR") {
      setİmage(cay);
    }
    else if (item.CATEGORY === "FILTRE KAHVE") {
      setİmage(filtre);
    }
    else if (item.CATEGORY === "MESRUBAT") {
      setİmage(soft);
    }
    else if (item.CATEGORY === "MILKSHAKE") {
      setİmage(milksake);
    }
    else if (item.CATEGORY === "MOJITO") {
      setİmage(mojito);
    }
    else if (item.CATEGORY === "NARGILE") {
      setİmage(nargile);
    }
    else if (item.CATEGORY === "OZEL SPECIALS") {
      setİmage(nargile);
    }
    else if (item.CATEGORY === "LIMONATA") {
      setİmage(limonata);
    }
    else if (item.CATEGORY === "SOGUK KAHVE") {
      setİmage(sogukkahve);
    }
    else if (item.CATEGORY === "TAZE SIKILMIS") {
      setİmage(tazesıkılmıs);
    }
    else if (item.CATEGORY === "TURK KAHVESI") {
      setİmage(türkkahvesi);
    }
    else if (item.CATEGORY === "PASTA") {
      setİmage(pasta);
    }
  }, [item.CATEGORY]);
  return (
    <div className="product-container">
   
      <div className="prodUct-img">
        <img
          // src={"data:image/jpeg; base64 ," + item.RESIM_DOSYASI_1}
          src={image}
          alt={item.URUN_ADI}
          className="product-image"
          onClick={() => {
            setmodal(true);
          }}
        />
      </div>
      <div
        className="product-info"
        onClick={() => {
          setmodal(true);
        }}
      >
        <div className="product-info-header">
          <p>{item.URUN_ADI}</p>
          <p>{item.FIYATI} ₺</p>
        </div>
        <hr className="stick" />
        <div className="product-infor-body">
          {" "}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione,
          esse!
        </div>
      </div>
      <Modal
        className="my-modal"
        centered
        size="md"
        isOpen={modal}
        toggle={() => setmodal(!modal)}
      >
        <ModalHeader toggle={() => setmodal(!modal)}>
          {item.URUN_ADI}
        </ModalHeader>
        <ModalBody>
          <div className="modal-container">
            <img
              src={"data:image/jpeg; base64 ," + item.RESIM_DOSYASI_1}
              alt={item.URUN_ADI}
              className="modal-image"
            />
            <div className="mt-2">
              <p className="modal-info">
                <span className="modal-info-header">Ürün Detayı: </span> Lorem
                ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut
              </p>
              <p className="text-end modal-info-price">{item.FIYATI} ₺</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setmodal(!modal)}>
            Kapat
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProductCard;
