import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import ProductCard from "./ProductCard";
import "../style/product.css";
import Backtotop from '../components/backtotop/Backtotop'

const Product = () => {
  let { categoryName } = useParams();

 

  const products = useSelector((state) => state.gallery.product);
  const [productItems, setProductItems] = useState();

  console.log(productItems);

  useEffect(() => {
    const urunler = products.filter((product) => {
      return product.CATEGORY === categoryName;
    });
    setProductItems(urunler);
  }, [categoryName, products]);

  return (
    <div className="App">
      <div className="header">
      <h4 className="text-center mt-2">{categoryName}</h4>
      </div>
     <Container>
       <Row>
         <Col lg="12" md="12" sm="12" xs="12" className="mt-2">
           <Row>
            {productItems && productItems.map((item) => (
               <Col
                 lg="12"
                 md="12"
                 sm="12"
                 xs="12"
                 key={item.URUN_KOD}
                 className="mb-2"
               >
               <ProductCard item={item}/>
               </Col>
             ))}
           </Row>
         </Col>
       </Row>
     </Container> 
     <Backtotop/>
  </div>
  );
};

export default Product;
