import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../style/category.css";
import { useNavigate } from "react-router-dom";

const Category = ({ categorys }) => {
  const navigate = useNavigate();
  // console.log(categorys);

  return (
    <Container>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <Row>
            {categorys.map((item, index) => (
              <Col lg="6" md="6" sm="6" xs="6" key={index} className=" colom">
                <div className="categroy-box"  onClick={() => navigate(`/product/${item.CATEGORY}`)}>
                  <img
                    src={"data:image/jpeg; base64 ," + item.RESIM_DOSYASI}
                    alt="Notebook"
                    className="category-image"
                  />
                  <div className="content">
                    <h1 className="category-name text-center">{item.CATEGORY}</h1>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Category;
