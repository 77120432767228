import React from "react";
import { BrowserRouter, Route, Routes,Navigate } from "react-router-dom";
import Home from './screen/Home';
import Product from './screen/Product';
import Error from './screen/Error';
import Loading from './screen/Loading'
import FirstPage from './screen/FirstPage'

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" exact  element={<Navigate to="/Loading" />}  />
        
      <Route path="/FirstPage" element={<FirstPage />} />
      <Route path="/Loading" element={<Loading />} />
      <Route path="/home" element={<Home />} />
      <Route path="/product/:categoryName" element={<Product />} />
      <Route path="*" element={<Error />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
