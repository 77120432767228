import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Backtotop = () => {
  // const [visible, setVisible] = useState(false);
  // const toggleVisible = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   if (scrolled > 0) {
  //     setVisible(true);
  //   } else if (scrolled <= 0) {
  //     setVisible(false);
  //   }
  // };
  // window.addEventListener("scroll", toggleVisible);
  return (
    <Link
      className="backtotop"
      id="backtotop"
      to="/home"
      // style={{ display: visible ? "inline" : "none" }}
    >
      <i className="fa-solid fa-circle-arrow-left"></i>
    </Link>
  );
};

export default Backtotop;
