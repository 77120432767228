import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getImage = createAsyncThunk("image/getImage", async () => {
  const response = await fetch(
    "https://hsmtxtzh1f.execute-api.eu-central-1.amazonaws.com/Prod/get_image",
    {
      method: "POST",
      body: JSON.stringify({ path: "mf1851/restaurant.txt" }),
      headers: {
        Accept: "application/json",
      },
    }
  );
  const imageResponse = await response.json();
  return imageResponse;
});

export const imageSlice = createSlice({
  name: "images",
  initialState: {
    image: [],
    isLoading: false,
  },
  extraReducers: {
    [getImage.pending]: (state) => {
      state.isLoading = true;
    },
    [getImage.fulfilled]: (state, action) => {
      state.image = action.payload;
      state.isLoading = false;
    },
    [getImage.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default imageSlice.reducer;
