import React from "react";
import mobres from "../assets/mobres-logo.png";
import { Link } from "react-router-dom";
import "../style/firspage.css";



const FirstPage = () => {

  return (

    <div className="firstContainer">
      <div className="centered">
        <img src={mobres} alt="" className="logo" />
        <Link to="/home" className="menu-buton">
          <h2 className="text-center">Mobres Mikom Yazılım</h2>
          <h5 className="text-center">Hoşgeldiniz</h5>
        </Link>
        <div className="text-center menu-button2">
            <Link to="/home" className="btn btn-danger mt-3">Menüye Git</Link>
        </div>
      </div>
      <div className="bottom-left">
        <a href="https://www.mikom.com.tr/" className="mobrecom">© 2022 | Mobres Mikom Yazılım</a>
      </div>
    </div>

  );
};

export default FirstPage;
