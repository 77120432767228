import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getProduct = createAsyncThunk("product/getProduct", async () => {
  const response = await fetch(
    "https://hsmtxtzh1f.execute-api.eu-central-1.amazonaws.com/Prod/get_image",
    {
      method: "POST",
      body: JSON.stringify({ path: "mf1851/products.txt" }),
      headers: {
        Accept: "application/json",
      },
    }
  );
  const formattedResponse = await response.json();
  return formattedResponse;
});

export const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    product: [],
    isLoading: false,
  },
  extraReducers: {
    [getProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.product = action.payload;
      state.isLoading = false;
    },
    [getProduct.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default gallerySlice.reducer;
